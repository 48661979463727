<!--  -->
<template>
  <div style="width: 100%; height: 100%">
    <div style="margin-bottom: 10px; width: 100%">
      <el-date-picker
        v-model="value1"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="mini"
         @change="changeList"
      >
      </el-date-picker>
    </div>
    <div id="myChart3" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import {PostCommoditySales} from "@/api/index.js"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    list: Object,
  },
  data() {
    //这里存放数据
    return {
      list2: [],
      value1: [],
         myChart: "",
      option: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
       //获取上一个月日期
    getLastMonth(date) {
     var now = new Date();
        var date = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString();
        if (month.length === 1) {
            month = "0" + month
        }
        var day = (date.getDate()).toString();
        if (day.length === 1) {
            day = "0" + day
        }
        var hour = date.getHours();
        var minute = date.getMinutes();
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
    },
       //获取当前年月日
    getDate(){
       var date = new Date();
       var year=date.getFullYear(); //获取完整的年份(4位)
       var month=date.getMonth()+1; //获取当前月份(0-11,0代表1月)
       var day=date.getDate(); //获取当前日(1-31); //获取当前月份(0-11,0代表1月)
       var time=year+'-'+month+'-'+day;  //YYYY-MM-dd
       return time;//返回当前日期YYYY-MM-dd
    },
          //连接日期获取商品总量数据网络请求
        changeList() {
          console.log(this.value1)
       this.PostCommoditySales({
        start_date:this.value1[0],
        end_date:this.value1[1],
       })
    },
    async getList() {
      // let info = await this.$axios.post("cheshia");
      // console.log(info);
      // this.list2 = info.data.data;
      this.drawLine();
    },
    drawLine() {
      var chartDom = document.getElementById("myChart3");
      this.myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        title: { text: "订单总数" },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
           axisLabel: {
            interval: 0,
            formatter:function(value){  
               return value.split("").join("\n");  
            }
        }
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [10, 20, 40, 50, 70, 100, 101],
            type: "line",
            areaStyle: {},
            // smooth: true,
          },
        ],
      };
      // option.xAxis.data = this.list2.mobile;
      // option.series[0].data = this.list2.member;
      this.option=option;
      option && this.myChart.setOption(option);
    },
      //-------------------------------网络请求函数---------------------------------------
        //分类商品网络请求函数
    async PostCommoditySales(data){
      let res=await PostCommoditySales(data);
      if(res.data.code==1){
                 var numArr=[];//销量数组
        var dateArr=[];//X轴日期数组
        res.data.data.forEach(item => {
          numArr.push(item.sales_num);
          dateArr.push(item.c_name.substring(0,4));
        });
        // console.log(numArr,date)
          //配置echars
        this.option.series[0].data=numArr;
        this.option.xAxis.data=dateArr;
        this.myChart.setOption(this.option);
         this.$message({
          message: res.data.message,
          type: 'success'
        });
      }else{
        this.$message.error(res.data.message);
      }
      console.log(res)
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var endDate=this.getDate();
    var stratDate=this.getLastMonth(endDate);
    this.value1.push(stratDate);
    this.value1.push(endDate);
    this.PostCommoditySales({
      end_date:this.value1[1],
      start_date:this.value1[0]
    });
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* #myChart {
  height: 100%;
  width: 100%;
} */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}
</style>